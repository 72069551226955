.countdown-product {
  display:         flex;
  flex-direction:  column;
  justify-content: center;
  align-items:     center;
  border:          1px solid $base-border-color;
  padding:         1em;

  &__row {
    display:   flex;
    flex-wrap: nowrap;
  }

  &__title {
    margin-right: 10px;
    color:        $base-font-color-secondary;
  }

  &__item {
    font-size:   1.4em;
    font-weight: bold;
    color:       $base-font-color-strong;
    white-space: nowrap;

    &::after {
      content: ':';
      display: inline-block;
    }

    &--no-marker {
      &::after {
        display: none;
      }
    }

    &--label {
      margin-right: 5px;
      margin-left:  5px;
    }
  }

}