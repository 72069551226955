.page {

  /* Mobile sidebar slide panel */
  &__mobile {
    position: fixed;
    width: $page-mobile-width;
    height: 100%;
    top: 0;
    left: -$page-mobile-width;
    z-index: $z-index-drop + 1;
    background: transparent url("../_img/header-bg.png") repeat left top;
    color: $page-mobile-color;
    overflow-y: auto;
    overflow-x: hidden;
    transition: transform $page-mobile-speed;

    /* enables momentum scrolling in iOS overflow elements */
    -webkit-overflow-scrolling: touch;

    &--js-open {
      transform: translateX($page-mobile-width);
    }

  }

  /* Page overlay visible if mobile panes is open */
  &__overlay {
    display: block;
    @include position(fixed, 0 0 0 0);
    z-index: $z-index-drop;
    background-color: $page-overlay-color;
    animation: fade 500ms;

    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  /*
    * Main site content frame
    * Shifting to right when mobile menu is siliding.
    * Adding via javascript by clicking on mobile menu btn
  */
  &__body {
    @include transition(transform $page-mobile-speed);

    &--js-pushed {
      @include transform(translateX($page-mobile-width));
      /* Disable scrolling while mobile bar is opened */
      height: 100vh;
      overflow: hidden;
    }
  }

  &__mainframe {
    @extend .container-fluid;
    max-width: $site-max-width;
  }

  /* Container for static width frames */
  &__container {
    //extending bootstrap container
    @extend .container-fluid;
    max-width: $site-max-width;
  }

  /* Main header */
  &__header {

  }

  /* Page content wrapper */
  &__content {
  }

  /* Main horisontal navigation wrapper */
  &__navbar {
    background-color: $theme-secondary-color;
  }

  /* Breadcrumbs wrapper */
  &__breadcrumbs {
    padding: 15px 0;
    border-bottom: 1px solid $base-border-color;
  }

  /* Site benefits wrapper */
  &__benefits {
    background: $benefits-bg-color;
    border-top: 1px solid $benefits-border-color;
    padding: 20px 0;
  }

  /* Page viewed wrapper */
  &__viewed {
    padding-bottom: 50px;
  }

  /* Main footer wrapper */
  &__footer {
    position: relative;
    background: transparent url("../_img/footer-bg.png") repeat left top;

    &:after {
      content: '';
      position: absolute;
      right: 50%; left: 0; bottom: 0; top: 0;
      background-color: rgba(0,0,0,.3);
      @extend .hidden-xs;
    }
  }

  /* Footer line in the bottom */
  &__basement {
    padding-bottom: 20px;
  }
}