.navbar{
  display: flex;
  min-height: $page-navbar-height;

  &__left{
    flex-basis: $page-sidebar-width;
    flex-shrink: 0;
    @extend .hidden-xs;
    @extend .hidden-sm;
    margin-right: $page-sidebar-indent;
    min-height: $page-navbar-height;
  }

  &__center{
    padding: 8px 0;
    flex-grow: 1;
    align-self: center;

    @media (min-width: $screen-md-min) {
      padding-right: $page-sidebar-indent;
    }
  }

  &__item{
    @extend .hidden-xs;
    @extend .hidden-sm;
    display: flex;
    flex-shrink: 0;
    position: relative;
    color: #fff;
    border-left: 1px solid #404040;

    &:last-child{
      border-right: 1px solid #404040;
    }

    &:hover {
      .navbar__link {
        color: $base-second-link-hover-color;
      }
    }
  }

  &__link{
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 35px 0 20px;
    color: #fff;
    text-decoration: none;

    &--empty {
      cursor: default;
    }
  }

  &__arrow{
    width: 11px;
    height: 11px;
    margin-top: -2px;
    position: absolute;
    right: 15px;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__drop{
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: $z-index-m;

    &--rtl {
      left: auto;
      right: 0;
    }
  }
  &__item:hover &__drop{
    display: block;
  }

}