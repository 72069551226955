//--------------------------------------------------
//--------------------------------------------------
//Base
//--------------------------------------------------
//--------------------------------------------------

//Size
$site-max-width:                  1530px;

//Theme
$theme-main-color:                #d00027;
$theme-secondary-color:           #121212;
$theme-third-color:               #fd9f3b;
$theme-frame-color:               #f8f8f8;

//Body
$base-font-family:                'Roboto', sans-serif;
$base-font-color:                 #666;
$base-font-color-secondary:       #999;
$base-font-color-strong:          #000;
$base-font-size:                  14px;
$base-line-height:                1.3;

//Links
$base-main-link-color:            $theme-secondary-color;
$base-main-link-hover-color:      #d00027;
$base-main-link-decor:            none;
$base-main-link-decor-hover:      underline;

$base-second-link-color:          #d7d7d7;
$base-second-link-hover-color:    #d00027;
$base-second-link-decor:          none;
$base-second-link-decor-hover:    none;

//Helpers
$base-border-color:               #c5c5c5;
$base-border-color-darken:        #c5c5c5;
$base-box-shadow-color:           rgba(0, 0, 0, 0.15);


//Bootstrap override
$border-radius-base: 0;

// Bourbon override
$em-base: $base-font-size;


$attention-color: #e75e2a;

//--------------------------------------------------
//Scaffolding
//--------------------------------------------------


// ----- Page ----- //
$page-sidebar-width:              270px;
$page-sidebar-indent:             30px;

$page-mobile-width:               250px;
$page-mobile-bg:                  $theme-main-color;
$page-mobile-active-bg:           lighten($page-mobile-bg, 5%);
$page-mobile-color:               #fff;
$page-mobile-speed:								0.3s;
$page-overlay-color:              rgba(0, 0, 0, 0.6);

// ----- navbar ----- //
$page-navbar-height:              50px;

// ----- Headline ----- //
$header-top-line-color:           $theme-secondary-color;
$header-delimiter-color:          #c5c5c5;


// ----- Footer ----- //
$footer-bg:                       $theme-secondary-color;
$footer-sidebar-bg:               darken($footer-bg, 4%);
$footer-border-color:             $base-border-color;
$footer-text-color:               #c8d5ff;
$footer-title-color:              #fff;
$footer-link-color:               #c5c5c5;
$footer-link-hover-color:         #ff6400;


// ----- Basement ----- //
$basement-text-color:             $base-font-color;
$basement-link-color:             $base-main-link-color;
$basement-link-hover-color:       $base-main-link-hover-color; 


//-------------------------------------------------------------------------
//Z Indexes.
//Custom z-indexes should be less than 100 to prevent Modal window overlay 
//-------------------------------------------------------------------------
$z-index-xs:  10;
$z-index-s:   $z-index-xs + 1;
$z-index-m:   $z-index-s + 1;
$z-index-l:   $z-index-m + 1;
$z-index-xl:  $z-index-l + 1;

$z-index-drop: 100;


//--------------------------------------------------
//Grid
//--------------------------------------------------
$grid-col-mobile-spacer:          20px;



//--------------------------------------------------
// Forms
//--------------------------------------------------
//Bootstrap override

$input-border:										$base-border-color;
$input-border-radius:             2px;
$input-border-radius-small:       0;
$input-border-radius-large:       0;
$input-group-addon-bg: 						$theme-frame-color;
$input-group-addon-border-color:  $input-border;



//--------------------------------------------------
// Buttons
//--------------------------------------------------

// ----- Bootstrap override ----- //
$btn-default-border:			        $base-border-color;
$btn-border-radius-base:          2px;
$btn-border-radius-small:         2px;
$btn-border-radius-large:         2px;
$btn-default-bg:                  #f8f8f8;

$btn-primary-bg:                   $theme-main-color;
$btn-primary-border:               darken($btn-primary-bg, 3%);
$btn-primary-bg-hover:             lighten($btn-primary-bg, 2%);
$btn-primary-border-hover:         lighten($btn-primary-border, 2%);
$btn-primary-border-radius:        2px;

// ----- Main button ----- //
$btn-main-bg:                     $theme-main-color;
$btn-main-bg-hover:               lighten($btn-main-bg, 20%);
$btn-main-color:                  #fff;
$btn-main-color-hover:            #fff;



//--------------------------------------------------
// Site info
//--------------------------------------------------
$site-info-color:                 #000;
$site-info-ico-color:             $theme-main-color;



//--------------------------------------------------
// Overlay
//--------------------------------------------------
$overlay-bg-color:                #fff;
$overlay-border-color:            $base-border-color;
$overlay-shadow-color:            $base-box-shadow-color;

$overlay-link-color:              $base-main-link-color;
$overlay-link-hover-color:        $base-main-link-color;
$overlay-link-hover-bg:           #F2F2F2;


//--------------------------------------------------
// Navigation
//--------------------------------------------------

// ----- table nav ----- //
$main-nav-bg-color:              #fff;
$main-nav-bg-hover-color:        #f2f2f2;
$main-nav-bg-active-color:       #f2f2f2;
$main-nav-link-color:            #000;
$main-nav-link-hover-color:      #000;
$main-nav-border-color:          $base-border-color;
$main-nav-arrow-color:           #999;

// ----- tree nav ----- //
$tree-nav-bg-color:               #fff;
$tree-nav-border-color:           $base-border-color;
$tree-nav-shadow-color:           $base-box-shadow-color;

$tree-nav-link-color:             #000;
$tree-nav-link-hover-color:       $base-second-link-hover-color;
$tree-nav-link-hover-bg:          #F2F2F2;


// ----- cols nav ----- //
$cols-nav-bg-color:               #fff;
$cols-nav-border-color:           $base-border-color;
$cols-nav-shadow-color:           $base-box-shadow-color;
$cols-subnav-col-width:           165px;


// ----- mobile nav ----- //
$mobile-nav-slide-speed:          $page-mobile-speed;
$mobile-nav-border-color:         lighten($page-mobile-bg, 5%);
$mobile-nav-link-color:           #fff;
$mobile-nav-viewall-color:   	    #fff;
$mobile-nav-caption-bg:           #000;
$mobile-nav-caption-color:        #fff;



// ----- board nav ----- //
$board-nav-bg-color:              $theme-secondary-color;
$board-nav-link-1-color:          #000;
$board-nav-link-1-color-hover:    #000;



//--------------------------------------------------
// Benefits
//--------------------------------------------------
$benefits-bg-color:               #fafafa;
$benefits-border-color:           $base-border-color;
$benefits-title-color:            #000;
$benefits-ico-color:              #000;
$benefits-desc-color:             #999;


//--------------------------------------------------
// Widgets
//--------------------------------------------------

// ----- Widget primary ----- //
$widget-primary-header-bg:            $theme-secondary-color;
$widget-primary-title-color:          #000;


// ----- Widget secondary ----- //
$widget-secondary-title-color:        #000;


// ----- Brands widget ----- //
$bradns-widget-border-color:        $base-border-color;

$bradns-widget-arrow-bg:            #fff;
$bradns-widget-arrow-bg-hover:      darken($bradns-widget-arrow-bg, 2%);
$bradns-widget-arrow-color:         #000;
$bradns-widget-arrow-color-hover:   #000;

$bradns-widget-link-color:          rgba(0,0,0,1);
$bradns-widget-link-color-hover:    rgba(0,0,0,0.7);


// ----- Brands image ----- //
$bradns-image-height:         120px;


// ----- SEO text ----- //
$seo-text-title-color:          #000;


// ----- Breadcrumbs ----- //



// ----- Star rating ----- //
$star-voting-color:             #FFB300;
$star-rating-color:             #FFB300;


// ----- Message ----- //
$message-success-color:         #dff0d8;
$message-success-border:        #dff0d8;

$message-error-color:           #ffefe8;
$message-error-border:          #e89b88;

$message-info-color:            #FCF8E3;
$message-info-border:           #efe4ae;



//--------------------------------------------------
// Product
//--------------------------------------------------
$product-photo-border-color:   $base-border-color;


$product-photo-fluid-height:    75%;

$product-photo-fix-height-xs:   60px;
$product-photo-fix-height-sm:   100px;
$product-photo-fix-height-md:   200px;
$product-photo-fix-height-lg:   300px;

$product-photo-zoom-width:  400px;
$product-photo-zoom-height: $product-photo-fix-height-lg;


$product-photo-thumbs-number:   5;
$product-photo-thumb-height:    60px;

$product-label-bg-default:         rgba(0, 0, 0, 0.5);
$product-label-bg-hit:             #fd9f3b;
$product-label-bg-hit-arrow:       #d17718;
$product-label-bg-hot:             #769c1f;
$product-label-bg-hot-arrow:       #5f7d1b;
$product-label-bg-action:          #ff6400;
$product-label-bg-action-arrow:    #cc5000;
$product-label-bg-discount:        #ff6400;
$product-label-bg-discount-arrow:  #cc5000;



//--------------------------------------------------
// Compare products
//--------------------------------------------------
$compare-product-height:            220px;
$compare-product-bg:                #f2f2f2;



//--------------------------------------------------
// Modal window
//--------------------------------------------------
$modal-size:                        500px;
$modal-size-sm:                     400px;
$modal-size-lg:                     800px;
$modal-hor-padding:                 25px;
$modal-header-bg:                   #f2f2f2;
$modal-header-border:               $base-border-color;
$modal-header-color:                #000;



//--------------------------------------------------
// Ajax Loaders
//--------------------------------------------------
$spinner-fa-size:                   45px;
$spinner-fa-color:                  #000;
$spinner-fa-image:                  '\f110';

$spinner-circle_speed:              .675s;
$spinner-circle_radius:             80%;
$spinner-circle_fill_radius:        90px;

$spinner-circle_size:               40px;
$spinner-circle_thickness:          10px;
$spinner-circle_inner_circle:       $spinner-circle_size - $spinner-circle_thickness;

$spinner-circle_color:              black;
$spinner-circle_bg:                 lightgray;
$spinner-circle_inner_bg:           white;


$mfp-image-background: #fff;
$mfp-controls-color: #fff;
