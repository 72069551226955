.footer {
  $vertical-indent: 30px;

  display: flex;
  flex-wrap: wrap;
  color: $footer-text-color;

  &__sidebar {
    width: $page-sidebar-width;
    margin-right: $page-sidebar-indent;
    padding: $vertical-indent 0;
    flex-shrink: 0;
    font-size: .9em;
    color: $footer-title-color;
    position: relative;
    z-index: 1;

    .footer__link {
      color: #999;
      text-decoration: underline;
    }
  }
  &__sidebar-content {
    position: relative;
  }
  &__sidebar-item {
    font-size: 12px;
    &:not(:first-child) {
      margin-top: 15px;
    }

    &--indent-lg {
      color: #999;


      &:not(:first-child) {
        margin-top: 35px;
      }
    }

    &--color-secondary {
      color: $footer-text-color;
    }
  }
  &__sidebar-second-type {
    display: block;
    color: #999;
    font-size: 12px;
  }
  &__body {
    flex-grow: 1;
    padding: $vertical-indent 0 $vertical-indent 0;
    background: transparent url("../_img/footer-bg.png") repeat left top;
    position: relative;
    z-index: 1;
  }

  &__title {
    font-weight: bold;
    color: $footer-title-color;
  }

  &__inner {
    margin-top: 15px;
  }

  &__row {
    display: flex;
    margin: 0 -15px;
    @media (min-width: $screen-sm) {
      justify-content: space-around;
    }
  }

  &__col {
    padding: 0 15px;
    max-width: 220px;
  }

  &__separator {
    width: 1px;
    border-right: 1px dashed #000;
  }

  &__items--ico &__item {
    display: flex;
  }

  &__item {
    margin-top: 10px;
    color: #c5c5c5;
    font-size: 13px;
  }

  &__link {
    color: $footer-link-color;
    text-decoration: none;

    &:hover {
      color: $footer-link-hover-color;
      text-decoration: none;
    }
  }

  &__ico {
    width: 16px;
    height: 16px;
    display: inline-block;
    color:$theme-main-color;
    margin-right: 5px;

    svg {
      vertical-align: middle;
    }
  }

}