.widget-primary {
  font-size: 1rem;

  &__title {
    margin-bottom: 1em;
    padding-bottom: .5em;
    border-bottom: 2px solid #545454;
    font-weight: bold;
    font-size: 2em;
    line-height: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $widget-primary-title-color;
  }

  &__inner{
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 22px;
    border-bottom: 1px dashed $base-border-color;
  }

  &__view-all-wrap {
    background-color: #fff;
    padding: 0 25px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 100%;
  }

  &__view-all{
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-lg;

    background-color: #545454;
    border: 0;
    padding-left: 65px;
    padding-right: 65px;
  }

}