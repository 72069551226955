.product-price {
  display:     flex;
  align-items: flex-end;
  font-weight: 700;

  &__old {
    position:     relative;
    margin-right: 10px;
    font-size:    16px;
    line-height:  1;
    color:        $base-font-color-secondary;
    white-space:  nowrap;
    font-weight: 700;

    /* line-through */
    &:after {
      content:    '';
      height:     1px;
      background: currentColor;
      position:   absolute;
      top:        0;
      right:      0;
      bottom:     0;
      left:       0;
      margin:     auto;
    }

    &-value {
      float: left;
    }

    &-coins {
      float: left;
    }

    &-cur {
      float: left;
      &:last-child {
        margin-left: .2em;
      }
    }
  }
  &--lg &__old {
    font-size:    1.4rem;
    margin-right: 10px;
  }

  &__main {
    font-size:   1.2rem;
    line-height: 1;
    font-weight: 500;
    white-space: nowrap;
    color: $theme-main-color;
    font-weight: bold;

    &--vertical {
      width:       100%;
      margin-left: 0;
    }

    &--small {
      font-size: 1rem;
    }

    &--lg {
      font-size: 1.5rem;
    }

    &-value {
      float: left;
    }

    &-coins {
      float: left;
    }

    &-cur {
      float: left;

      &:last-child {
        margin-left: .2em;
      }
    }
  }
  &--lg &__main {
    font-size: rem(28);
  }

  &__addition {
    margin-left: 5px;
    font-size:   0.9rem;
    line-height: 1;
    color:       $base-font-color-secondary;

    &-item {
      float: left;

      &:not(:first-child) {
        &:before {
          content: '/';
          float:   left;
          margin:  1px 5px 0 5px;
        }
      }
    }

    &-value {
      float: left;
    }

    &-coins {
      float: left;
    }

    &-cur {
      float: left;
      &:last-child {
        margin-left: .2em;
      }
    }
  }
  &--lg &__addition {
    font-size:   1.2rem;
    margin-left: 10px;
  }

}