.site-info {
  color: $site-info-color;
  font-size: .9rem;

  &__group{
    display: flex;
    align-items:flex-end;
    flex-wrap: wrap;

    &:not(:first-child){
      padding-top: 10px;
    }
  }
  &__phones {
    display: flex;

  }
  &__aside {
    padding-right: 5px;
  }

  &__inner{
    margin-left: -10px;
    margin-top: -5px;
  }

  &__item{
    white-space: nowrap;
    display: block;
    padding-left: 10px;
    padding-top: 5px;

    &--phone {
      font-size: 1rem;
      font-weight: bold;
      color: inherit;
      text-decoration: none;
      color: #fffefe;
      font-size: 21px;
    }
    &--btn {
      color: #fff;
      margin-left: 10px;
      padding: 0;

      .link {
        padding: 5px 7px;
        margin-top: 10px;
        font-size: 14px;
        color: #fff;
        text-decoration: none;
        display: block;
        background-color: $theme-main-color;
        min-width: 125px;

        &:hover {
          color: #fff;
          text-decoration: none;
          opacity: .8;
        }
      }
    }
  }

  &__icon {
    position: relative;    
    color: $site-info-ico-color;

    &--phone{
      top: 6px;
      width: 17px;
      height: 17px;
    }

    &--clock{
      width: 16px;
      height: 16px;
    }
  }

}